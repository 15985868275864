
import ApiService from '@/core/services/ApiService'
import moment from 'moment'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as Yup from 'yup'
import { AxiosRequestConfig } from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useStore } from 'vuex'
import { event } from 'vue-gtag'

interface Submission {
    id: string
    photo?: string
    fullname: string
    createdAt: string
    answers: any[]
    color: string
    status: string
    note?: number
    currentNote?: number
    validatedNote: boolean
}

export default defineComponent({
    name: 'exercise-submissions',
    props: {
        homework: String,
        marked: Boolean,
        questions: Object,
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props) {
        const { t } = useI18n()
        const buttonNoteRef = ref<null | HTMLButtonElement>(null)
        const submissionUpdate = ref<boolean>(false)
        const store = useStore()

        const list = ref<Submission[]>([])
        const selectedItem = ref<Submission>({
            id: '',
            fullname: '',
            createdAt: '',
            color: '',
            status: '',
            answers: [],
            validatedNote: false,
        })

        const validationSchema = Yup.object().shape({
            note: Yup.number()
                .transform((value) => (isNaN(value) ? null : value))
                .min(0, t('course.noteRule'))
                .max(20, t('course.noteRule'))
                .nullable(true),
        })

        const getPhotoURL = (photo: string) => {
            return (
                store.getters.serverConfigUrl.base_url +
                '/' +
                photo.replace(/\\/g, '/')
            )
        }

        ApiService.get(`/lms/quiz/${props.homework}/submissions`)
            .then(({ data }) => {
                data.forEach((submission) => {
                    const sb: Submission = {
                        id: submission._id,
                        fullname:
                            submission.student.firstName +
                            ' ' +
                            submission.student.lastName,
                        photo: submission.student.photo,
                        answers: submission.answers,
                        note: submission.note,
                        currentNote: submission.note,
                        validatedNote: submission.validatedNote,
                        createdAt: submission.createdAt,
                        color: 'success',
                        status: 'submitted',
                    }
                    if (submission.student.photo)
                        sb.photo = getPhotoURL(submission.student.photo)
                    list.value.push(sb)
                })
            })
            .catch((e) => {
                console.log(e)
            })

        const saveNote = () => {
            if (buttonNoteRef.value) {
                buttonNoteRef.value.disabled = true
                buttonNoteRef.value.setAttribute('data-kt-indicator', 'on')
            }

            event('Add mark quiz-submission', {
                event_category: 'Exercise',
                event_label: 'Exercise section',
                value: 1,
            })

            ApiService.patch(
                `/lms/quiz/submissionNote/${selectedItem.value.id}`,
                {
                    note: selectedItem.value.currentNote,
                    validatedNote: selectedItem.value.validatedNote,
                } as AxiosRequestConfig
            )
                .then(() => {
                    if (buttonNoteRef.value) {
                        buttonNoteRef.value.disabled = false
                        buttonNoteRef.value.removeAttribute('data-kt-indicator')
                    }
                    submissionUpdate.value = true
                    selectedItem.value.note = selectedItem.value.currentNote
                    setTimeout(() => {
                        submissionUpdate.value = false
                    }, 3000)
                })
                .catch((e) => {
                    Swal.fire({
                        text: t('course.errorText'),
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: t('course.tryAgain'),
                        customClass: {
                            confirmButton: 'btn fw-bold btn-light-danger',
                        },
                    })
                    buttonNoteRef.value!.disabled = false
                    buttonNoteRef.value?.removeAttribute('data-kt-indicator')
                    console.log(e)
                })
        }

        return {
            buttonNoteRef,
            list,
            selectedItem,
            t,
            validationSchema,
            saveNote,
            moment,
            submissionUpdate,
        }
    },
})
